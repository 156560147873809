import { SET_AUTH, START_LOGIN, OBTAIN_LOGIN_DATA, OBTAIN_LOGIN_ERROR } from "../actions";

const initialState = {
  isLoading: false,
  isAuth: !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER),
  profile: {
    id: null,
    login: "",
    role: null,
  },
  errors: [],
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH:
      return { ...state, isAuth: action.payload };
    case START_LOGIN:
      return {
        ...state,
        isLoading: true,
        isAuth: false,
        profile: initialState.profile,
        errors: [],
      };
    case OBTAIN_LOGIN_DATA:
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        profile: {
          id: action.payload.id,
          login: action.payload.login,
          role: action.payload.role,
        },
        errors: [],
      };

    case OBTAIN_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        isAuth: false,
        profile: initialState.profile,
        errors: action.payload,
      };
    default:
      return state;
  }
}
